
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from '@/services/StorageServices';
import LanguageModal from '@/components/modals/languageModal.vue';
import SelectedLanguageFlag from '@/components/selectedLanguageFlag.vue';
import InsertCodeOverlay from '@/components/insertCodeOverlay.vue';
import * as OM from '@/model';

@Options({
    components: {
        SelectedLanguageFlag,
        InsertCodeOverlay
    }
})
export default class LoggedUserLayout extends Vue {

    lingua: string = "";
    showPinCodeOverlay: boolean = false;
    exhibition: OM.GetExhibitionVm = StorageServices.getExhibition();
    
    openLanguageModal(){
        this.$opModal.show(LanguageModal, {});
    }
    
    searchForOpera(pin: string) {
        this.showPinCodeOverlay = false;
        this.$router.push('/exhibition-item/' + pin);
    }
    searchForOperaByTitle(title: string) {
        this.showPinCodeOverlay = false;
        this.$router.push('/exhibition-item/search/' + title);
    }
    backToExhibitionPage() {
        this.showPinCodeOverlay = false;
        this.$router.replace('/exhibition/' + this.exhibition.identifier);
    }
    
    // fullscreen: boolean = false;
    // canFullscreen: boolean = !(!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform));
    // toggleFullScreen() {
    //     this.fullscreen = !this.fullscreen;
    //     console.log(this.fullscreen);
    //     let pippo: any = document;
    //     var isInFullScreen = (pippo.fullscreenElement && pippo.fullscreenElement !== null) ||
    //         (pippo.webkitFullscreenElement && pippo.webkitFullscreenElement !== null) ||
    //         (pippo.mozFullScreenElement && pippo.mozFullScreenElement !== null) ||
    //         (pippo.msFullscreenElement && pippo.msFullscreenElement !== null);

    //     var docElm = pippo.documentElement;
    //     if (!isInFullScreen) {
    //         if (docElm.requestFullscreen) {
    //             docElm.requestFullscreen();
    //         } else if (docElm.mozRequestFullScreen) {
    //             docElm.mozRequestFullScreen();
    //         } else if (docElm.webkitRequestFullScreen) {
    //             docElm.webkitRequestFullScreen();
    //         } else if (docElm.msRequestFullscreen) {
    //             docElm.msRequestFullscreen();
    //         }
    //     } else {
    //         if (pippo.exitFullscreen) {
    //             pippo.exitFullscreen();
    //         } else if (pippo.webkitExitFullscreen) {
    //             pippo.webkitExitFullscreen();
    //         } else if (pippo.mozCancelFullScreen) {
    //             pippo.mozCancelFullScreen();
    //         } else if (pippo.msExitFullscreen) {
    //             pippo.msExitFullscreen();
    //         }
    //     }
    // }

}
